export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'QB Takeover',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'WELCOME!',
  'client_theme.ThemeSidebar.mission':
    'Here at QB Takeover we help the best quarterbacks in the world, become better! Through actionable training and the personal guidance of our staff, our quarterbacks are able to excel on the field and take their game to the next level.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
};
